<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-12">
                <div class="card my-4">
                    <div class="bg-header-table shadow-success border-radius-lg pt-4 pb-3">
                        <div class="d-flex justify-content-between">
                            <h6 class="text-white text-capitalize ps-3">Manage Treasury Transactions</h6>
                            <div>
                                <el-button @click="exportTransactions('xlsx')" type="success" class="mr-3">Export as XLSX</el-button>
                                <el-button @click="exportTransactions('csv')" type="warning" class="mr-3">Export as CSV</el-button>
                                <el-button @click="fetchTransactions" type="primary" circle class="mr-5">
                                    <el-icon style="vertical-align: middle">
                                        <Document />
                                    </el-icon>
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table :data="transactions" style="width: 100%">
                            <el-table-column prop="treasury.name" label="Treasury Name" />
                            <el-table-column prop="amount" label="Amount" />
                            <el-table-column prop="description" label="Description" />
                            <el-table-column prop="transaction_type" label="Transaction Type">
                                <template #default="scope">
                                    <div :class="{'green-bg': scope.row.transaction_type === 'deposit', 'orange-bg': scope.row.transaction_type === 'disbursement'}">
                                        {{ scope.row.transaction_type }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="created_at" label="Date" />
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Document } from '@element-plus/icons-vue';
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

export default {
    name: 'TreasuryTransactions',
    components: {
        Document,
    },
    data() {
        return {
            transactions: [],
        };
    },
    methods: {
        async fetchTransactions() {
            try {
                const response = await api.get('/treasury-transactions');
                this.transactions = response.data;
            } catch (error) {
                this.$message.error('Failed to fetch transactions');
            }
        },
        exportTransactions(format) {
    window.location.href = `${api.defaults.baseURL}/treasury-transactions/export/${format}`;
}

    },
    mounted() {
        this.fetchTransactions();
    }
};
</script>

<style scoped>
.green-bg {
    background-color: #d4edda;
    color: #155724;
}
.orange-bg {
    background-color: #ffeeba;
    color: #856404;
}
.bg-header-table {
    background-color: #343a40;
}
.el-table .el-table__body tr:hover {
    background-color: #f8f9fa;
}
</style>
