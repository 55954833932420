
import { createApp } from "vue";

import App from "./App.vue";
import store from "./store";
import 'primeicons/primeicons.css';
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import MaterialDashboard from "./material-dashboard";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';



const originalConsoleError = console.error;
console.error = (...args) => {
  if (args[0].includes('ResizeObserver loop completed with undelivered notifications.')) {
    return;
  }
  originalConsoleError(...args);
};
  

const appInstance = createApp(App);

appInstance.use(PrimeVue, {
    theme: {
        preset: Aura,
    }
});
appInstance.config.errorHandler = (err, vm, info) => {
    // Suppress the specific ResizeObserver error
    if (err.message === 'ResizeObserver loop completed with undelivered notifications.') {
      return;
    }
    console.error('Captured in Vue global handler:', err, info);
  };
  

  
appInstance.use(store);
appInstance.use(router);
appInstance.use(ElementPlus);
appInstance.use(MaterialDashboard);
appInstance.mount("#app");
