<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div :class="{'dark-mode': isDarkMode}" class="card my-4">
          <div class="bg-header-table shadow-success border-radius-lg pt-4 pb-3">
            <div class="d-flex justify-content-around">
              <h6 class="text-white text-capitalize ps-3">Manage Students</h6>
              <el-input
                style="width: 200px;"
                v-model="searchQuery"
                placeholder="Search students..."
                class="search-input"
                @input="fetchStudents"
              />
              <el-button @click="dialogVisible = true" type="success" circle class="mr-5">
                <el-icon style="vertical-align: middle">
                  <Document />
                </el-icon>
              </el-button>
            </div>
          </div>
          <div>
            <el-table :data="students" style="width: 100%">
              <el-table-column type="selection" width="55" />
              <el-table-column property="name" label="Name" width="120" />
              <el-table-column property="date_of_birth" label="Date of Birth" width="120" />
              <el-table-column property="address" label="Address" width="240" />
              <el-table-column property="year" label="Year" width="240" />
              <el-table-column property="national_number" label="National Number" width="240" />
              <el-table-column property="parent.first_name" label="Parent First Name" width="120" />
              <el-table-column property="parent.last_name" label="Parent Last Name" width="120" />
              <el-table-column property="class.name" label="Class" width="120" />
              <el-table-column property="bus.number" label="Bus" width="120" />
              <el-table-column label="Operations" width="300">
                <template #default="scope">
                  <el-button @click="showStudent(scope.row)" type="primary" size="mini">Show</el-button>
                  <el-button @click="editStudent(scope.row)" type="warning" size="mini">Edit</el-button>
                  <el-button @click="deleteStudent(scope.row.id)" type="danger" size="mini">Delete</el-button>
                  <el-button @click="printStudent(scope.row)" type="info" circle size="large">
                    <el-icon style="vertical-align: middle">
                      <Printer />
                    </el-icon>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="d-flex justify-content-center mt-4">
              <el-pagination
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              :total="totalStudents"
              layout="prev, pager, next"
              background
            />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="Add Student" width="800">
      <span>Please fill out the form below</span>
      <el-steps :active="activeStep" finish-status="success">
        <el-step title="Personal Details"></el-step>
        <el-step title="Parent Information"></el-step>
        <el-step title="Class Assignment"></el-step>
        <el-step title="Bus Assignment"></el-step>
        <!-- <el-step title="Subscription Fees"></el-step> -->
      </el-steps>
      <div v-if="activeStep === 0">
        <el-form :model="form">
          <el-form-item label="Name" :rules="[{ required: true, message: 'Please enter name', trigger: 'blur' }]">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="Date of Birth" :rules="[{ required: true, message: 'Please select date of birth', trigger: 'blur' }]">
            <el-date-picker v-model="form.date_of_birth" type="date" placeholder="Pick a date"></el-date-picker>
          </el-form-item>
          <el-form-item label="Address" :rules="[{ required: true, message: 'Please enter address', trigger: 'blur' }]">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item label="Year" :rules="[{ required: false, message: 'Please enter year', trigger: 'blur' }]">
            <el-input v-model="form.year"></el-input>
          </el-form-item>
          <el-form-item label="National Number" :rules="[{ required: true, message: 'Please enter National Number', trigger: 'blur' }]">
            <el-input v-model="form.national_number"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="activeStep === 1">
        <el-form :model="form">
          <el-form-item label="Parent" :rules="[{ required: true, message: 'Please select parent', trigger: 'change' }]">
            <el-select v-model="form.parent_id" placeholder="Select Parent">
              <el-option v-for="parent in parents" :key="parent.id" :label="`${parent.first_name} ${parent.last_name}`" :value="parent.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="activeStep === 2">
        <el-form :model="form">
          <el-form-item label="Class" :rules="[{ required: true, message: 'Please select class', trigger: 'change' }]">
            <el-select v-model="form.class_id" @change="fetchSections" placeholder="Select Class">
              <el-option v-for="schoolClass in classes" :key="schoolClass.id" :label="schoolClass.name" :value="schoolClass.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Section" :rules="[{ required: true, message: 'Please select section', trigger: 'change' }]">
            <el-select v-model="form.section_id" placeholder="Select Section">
              <el-option v-for="section in sections" :key="section.id" :label="section.name" :value="section.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="activeStep === 3">
        <el-form :model="form">
          <el-form-item label="Bus" :rules="[{ required: true, message: 'Please select bus', trigger: 'change' }]">
            <el-select v-model="form.bus_id" placeholder="Select Bus">
              <el-option v-for="bus in buses" :key="bus.id" :value="bus.id" :label="bus.number"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div v-if="activeStep === 4">
        <el-form :model="form">
          <el-form-item label="Registration Fees">
            <el-select v-model="form.registration_fee" placeholder="Select Registration Fee">
              <el-option v-for="fee in subscriptionFees.registration" :key="fee.id" :label="fee.sub_category" :value="fee.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Books">
            <el-select v-model="form.books_fee" placeholder="Select Books Fee">
              <el-option v-for="fee in subscriptionFees.books" :key="fee.id" :label="fee.sub_category" :value="fee.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Uniform">
            <el-select v-model="form.uniform_fee" placeholder="Select Uniform Fee">
              <el-option v-for="fee in subscriptionFees.uniform" :key="fee.id" :label="fee.sub_category" :value="fee.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Tuition Fees">
            <el-select v-model="form.tuition_fee" placeholder="Select Tuition Fee">
              <el-option v-for="fee in subscriptionFees.tuition" :key="fee.id" :label="fee.sub_category" :value="fee.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Transportation">
            <el-select v-model="form.transportation_fee" placeholder="Select Transportation Fee">
              <el-option v-for="fee in subscriptionFees.transportation" :key="fee.id" :label="fee.sub_category" :value="fee.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Food">
            <el-select v-model="form.food_fee" placeholder="Select Food Fee">
              <el-option v-for="fee in subscriptionFees.food" :key="fee.id" :label="fee.sub_category" :value="fee.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-form-item label="Treasury">
          <el-select v-model="form.treasury_id" placeholder="Select Treasury">
            <el-option v-for="treasury in treasuries" :key="treasury.id" :label="treasury.name" :value="treasury.id"></el-option>
          </el-select>
        </el-form-item>
      </div> -->
      <div class="step-actions">
        <el-button v-if="activeStep > 0" @click="prevStep">Previous</el-button>
        <el-button v-if="activeStep < 3" type="primary" @click="nextStep">Next</el-button>
        <el-button v-if="activeStep === 3" type="success" @click="submitForm">Submit</el-button>
      </div>
    </el-dialog>

    <el-dialog v-model="showDialogVisible" title="Student Details" width="800">
      <div v-if="selectedStudent">
        <p><strong>Name:</strong> {{ selectedStudent.name }}</p>
        <p><strong>Date of Birth:</strong> {{ selectedStudent.date_of_birth }}</p>
        <p><strong>Address:</strong> {{ selectedStudent.address }}</p>
        <p><strong>Year:</strong> {{ selectedStudent.year }}</p>
        <p><strong>National Number:</strong> {{ selectedStudent.national_number }}</p>
        <p><strong>Parent:</strong> {{ selectedStudent.parent?.first_name ?? 'N/A' }} {{ selectedStudent.parent?.last_name ?? '' }}</p>
        <p><strong>Class:</strong> {{ selectedStudent.class?.name ?? 'N/A' }}</p>
        <p><strong>Bus:</strong> {{ selectedStudent.bus?.number ?? 'N/A' }}</p>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { ElMessage } from 'element-plus';
import { Document, Printer } from '@element-plus/icons-vue';
import axios from 'axios';
import { mapActions,mapGetters } from "vuex";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

export default {
  name: "Students",
  components: {
    Document,
    Printer,
  },
  data() {
    return {
      activeStep: 0,
      dialogVisible: false,
      showDialogVisible: false,
      form: {
        id: null,
        name: '',
        date_of_birth: '',
        address: '',
        year: '',
        national_number:'',
        parent_id: '',
        class_id: '',
        section_id: '',
        registration_fee: '',
        bus_id: '',
        books_fee: '',
        uniform_fee: '',
        transportation_fee: '',
        food_fee:'',
        tuition_fee: '',
        treasury_id: null,
      },
      treasuries: [],
      searchQuery: '',
      students: [],
      parents: [],
      classes: [],
      sections: [],
      buses: [],
      subscriptionFees: {
        registration: [],
        books: [],
        uniform: [],
        transportation_food: [],
        tuition: [],
      },
      currentPage: 1,
      pageSize: 10,
      totalStudents: 0
    };
  },
  methods: {
    async fetchTreasuries() {
        try {
          const response = await api.get('/treasuries');
          this.treasuries = response.data.filter(treasury => treasury.type === 'manual');
        } catch (error) {
          this.$message.error('Failed to fetch treasuries');
        }
      },
    async fetchStudents() {
      try {
        const response = await api.get('/students', {
          params: {
            search: this.searchQuery,
            per_page: this.pageSize,
            page: this.currentPage
          }
        });
        this.students = response.data.data;
        this.totalStudents = response.data.total;
      } catch (error) {
        ElMessage.error('Failed to fetch students');
      }
    },
    async fetchParents() {
      try {
        const response = await api.get('/parents');
        this.parents = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch parents');
      }
    },
    async fetchClasses() {
      try {
        const response = await api.get('/classes');
        this.classes = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch classes');
      }
    },
    async fetchSections() {
      try {
        const response = await api.get('/sections', { params: { class_id: this.form.class_id } });
        this.sections = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch sections');
      }
    },
    async fetchBuses() {
      try {
        const response = await api.get('/buses');
        this.buses = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch buses');
      }
    },
    async fetchSubscriptionFees() {
  try {
    const response = await api.get('/subscription-fees');
    const fees = response.data;
    this.subscriptionFees = {
      registration: fees.filter(fee => fee.category === 'Registration Fees'),
      books: fees.filter(fee => fee.category === 'Books'),
      uniform: fees.filter(fee => fee.category === 'Uniform'),
      transportation: fees.filter(fee => fee.category === 'Transportation' && fee.sub_category === 'Bus Service'),
      food: fees.filter(fee => fee.category === 'Food' && fee.sub_category === 'Meals'),
      tuition: fees.filter(fee => fee.category === 'Tuition Fees')
    };
  } catch (error) {
    ElMessage.error('Failed to fetch subscription fees');
  }
},
async submitForm() {
  if (this.form.date_of_birth instanceof Date) {
    this.form.date_of_birth = this.form.date_of_birth.toISOString().split('T')[0];
  }
  try {
    let response;
    if (this.form.id) {
      response = await api.put(`/students/${this.form.id}`, this.form);
      ElMessage.success('Student updated successfully');
    } else {
      response = await api.post('/students', this.form);
      ElMessage.success('Student added successfully');
    }
    const studentId = response.data.id;
    const subscriptionFeesPayload = [
      this.form.registration_fee ? { subscription_fee_id: this.form.registration_fee, amount: this.subscriptionFees.registration.find(fee => fee.id === this.form.registration_fee).amount } : null,
      this.form.books_fee ? { subscription_fee_id: this.form.books_fee, amount: this.subscriptionFees.books.find(fee => fee.id === this.form.books_fee).amount } : null,
      this.form.uniform_fee ? { subscription_fee_id: this.form.uniform_fee, amount: this.subscriptionFees.uniform.find(fee => fee.id === this.form.uniform_fee).amount } : null,
      this.form.transportation_fee ? { subscription_fee_id: this.form.transportation_fee, amount: this.subscriptionFees.transportation.find(fee => fee.id === this.form.transportation_fee).amount } : null,
      this.form.food_fee ? { subscription_fee_id: this.form.food_fee, amount: this.subscriptionFees.food.find(fee => fee.id === this.form.food_fee).amount } : null,
      this.form.tuition_fee ? { subscription_fee_id: this.form.tuition_fee, amount: this.subscriptionFees.tuition.find(fee => fee.id === this.form.tuition_fee).amount } : null
    ].filter(fee => fee !== null); // Filter out null values

    const payload = {
          subscriptionFees: subscriptionFeesPayload,
          treasury_id: this.form.treasury_id,
        };
        if (subscriptionFeesPayload.length > 0) {
          await api.post(`/students/${studentId}/subscription-fees`, payload);
        }

    this.resetForm();
    this.fetchStudents();
  } catch (error) {
    if (error.response && error.response.data.errors) {
      // Loop through the validation errors and show them
      const errors = error.response.data.errors;
      for (const field in errors) {
        if (errors.hasOwnProperty(field)) {
          errors[field].forEach(errorMessage => {
            ElMessage.error(errorMessage);
          });
        }
      }
    } else {
      ElMessage.error('Failed to save student');
    }
  }

},

    async deleteStudent(id) {
      try {
        await api.delete(`/students/${id}`);
        ElMessage.success('Student deleted successfully');
        this.fetchStudents();
      } catch (error) {
        ElMessage.error('Failed to delete student');
      }
    },
    async showStudent(student) {
      try {
        const response = await api.get(`/students/${student.id}`);
        this.selectedStudent = response.data;
        this.showDialogVisible = true;
      } catch (error) {
        this.$message.error('Failed to fetch student details');
      }
    },
    async printStudent(student) {
      try {
        const response = await api.get(`/students/${student.id}`);
        const studentDetails = response.data;

        // Open a new page with the invoice
        const invoiceUrl = `/invoice.html?id=${student.id}`;
        window.open(invoiceUrl, '_blank');
      } catch (error) {
        console.error('Error fetching student details for printing:', error);
        this.$message.error('Failed to fetch student details for printing');
      }
    },
    editStudent(student) {
      this.form = { ...student, date_of_birth: new Date(student.date_of_birth) };
      this.dialogVisible = true;
    },
    resetForm() {
      this.form = {
        id: null,
        name: '',
        date_of_birth: '',
        address: '',
        year: '',
        national_number: '',
        parent_id: '',
        class_id: '',
        section_id: '',
        registration_fee: '',
        bus_id: '',
        books_fee: '',
        uniform_fee: '',
        transportation_fee: '',
        food_fee:'',
        tuition_fee: ''
      };
      this.dialogVisible = false;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fetchStudents();
    },
    handleSearch() {
      this.currentPage = 1; // Reset to first page on new search
      this.fetchStudents();
    },
    prevStep() {
      this.activeStep--;
    },
    nextStep() {
      this.activeStep++;
    }
  },
  computed: {
    pagedStudents() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filteredStudents.slice(startIndex, endIndex);
    },
    filteredStudents() {
      const query = this.searchQuery.toLowerCase();
      return this.students.filter(student => {
        return (
          student.name.toLowerCase().includes(query) ||
          (student.bus && student.bus.number.toLowerCase().includes(query))
        );
      });
    },
    isDarkMode() {
      // Access the `isDarkMode` state from Vuex or another state management system
      return this.$store.state.isDarkMode;
    },
    ...mapGetters(['isDarkMode']),
  },
  mounted() {
    this.fetchTreasuries();
    this.fetchStudents();
    this.fetchParents();
    this.fetchClasses();
    this.fetchBuses();
    this.fetchSubscriptionFees();
  },
  watch: {
    searchQuery: 'handleSearch'
  }
};
</script>




<style scoped>
.bg-header-table {
    
}

.search-input {
    width: 300px;
    margin-right: 10px;
}

.step-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
</style>
