<template>
    <el-card>
      <h3>Issuance of Financial Documents</h3>
  
      <el-form :model="form" @submit.prevent="submitForm">
        <!-- Select Student -->
        <el-form-item label="Select Student" required>
            <el-select v-model="form.student_id" placeholder="Select a student" @change="fetchParentAndFees" filterable>
              <el-option v-for="student in filteredStudents" :key="student.id" :label="student.name" :value="student.id" />
            </el-select>
          </el-form-item>
          
  
        <!-- Display Parent Name -->
        <el-form-item label="Parent Name" v-if="parent">
          <el-input v-model="formattedParentName" disabled></el-input>
        </el-form-item>
  
        <!-- Subscription Fees -->
        <el-form-item label="Subscription Fees" required>
          <el-select v-model="selectedFees" multiple placeholder="Select subscription fees" @change="updateFeeAmounts">
            <el-option v-for="fee in subscriptionFees" :key="fee.id" :label="`${fee.category} - ${fee.sub_category} - ${fee.amount}$`" :value="fee.id" />
          </el-select>
        </el-form-item>
  
        <!-- Fee Amounts -->
        <div v-for="(fee, index) in form.subscriptionFees" :key="index">
          <el-form-item :label="`Amount for ${fee.category} - ${fee.sub_category}`">
            <el-input v-model.number="fee.amount" type="number" disabled></el-input>
          </el-form-item>
        </div>
  
        <!-- Select Treasury -->
        <el-form-item label="Select Treasury" required>
          <el-select v-model="form.treasury_id" placeholder="Select a treasury" filterable>
            <el-option v-for="treasury in treasuries" :key="treasury.id" :label="treasury.name" :value="treasury.id" />
          </el-select>
        </el-form-item>
  
        <!-- Payment Method -->
        <el-form-item label="Payment Method" required>
          <el-radio-group v-model="form.payment_method">
            <el-radio label="cash">Cash</el-radio>
            <el-radio label="instrument">Financial Instrument</el-radio>
          </el-radio-group>
        </el-form-item>
  
        <!-- Bank Information (Visible only if instrument is selected) -->
        <el-form-item v-if="form.payment_method === 'instrument'" label="Bank Name">
          <el-input v-model="form.bank_name" placeholder="Enter bank name" />
        </el-form-item>
  
        <el-form-item v-if="form.payment_method === 'instrument'" label="Branch Name">
          <el-input v-model="form.branch_name" placeholder="Enter branch name" />
        </el-form-item>
  
        <el-form-item v-if="form.payment_method === 'instrument'" label="Account Number">
          <el-input v-model="form.account_number" placeholder="Enter account number" />
        </el-form-item>
  
        <!-- Value Received -->
        <el-form-item label="Value Received" required>
          <el-input v-model.number="form.value_received" type="number" placeholder="Enter value received" />
        </el-form-item>
  
        <!-- Discount (Optional) -->
        <el-form-item label="Discount (Optional)">
          <el-input v-model.number="form.discount" type="number" placeholder="Enter discount" />
        </el-form-item>
  
        <!-- Submit Form -->
        <el-form-item>
          <el-button type="primary" @click="submitForm">Issue Document</el-button>
        </el-form-item>
      </el-form>
  
      <!-- Show Receipt Number After Document is Issued -->
      <div v-if="receiptNumber">
        <el-alert title="Financial Document Issued" type="success" />
        <div>
            Receipt Number: {{ receiptNumber }}
        </div>
      </div>
  
      <!-- Optional: Display Logo -->
      <img :src="logo" alt="School Logo" class="logo"/>
    </el-card>
  </template>
  
  <script>
  import axios from "axios";
  import { ElMessage } from 'element-plus';
  import logo from "@/assets/img/logo-school.png";
  
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  export default {
    data() {
      return {
        students: [],
        filteredStudents: [], 
        subscriptionFees: [],
        treasuries: [],
        parent: null,
        form: {
          student_id: null,
          subscriptionFees: [], // Now stores objects with fee ID and amount
          treasury_id: null,
          payment_method: '',
          value_received: '',
          bank_name: '',
          branch_name: '',
          account_number: '',
          discount: '',
        },
        selectedFees: [],
        receiptNumber: null,
      };
    },
    computed: {
      formattedParentName() {
        if (this.parent) {
          return `${this.parent.first_name} ${this.parent.last_name}`;
        }
        return '';
      }
    },
    methods: {
      async fetchSubscriptionFees(studentId) {
        try {
          const response = await api.get(`/subscription-fees/${studentId}`);
          this.subscriptionFees = response.data.subscriptionFees;
          this.selectedFees = response.data.selectedFees.map(fee => fee.id); // Select IDs of the fees
          this.form.subscriptionFees = response.data.selectedFees; // Store full fee objects
          this.updateFeeAmounts();
        } catch (error) {
          ElMessage.error('Failed to fetch subscription fees');
        }
      },
      fetchStudents() {
        api.get('/students-index').then(response => {
          this.students = response.data;
          this.filteredStudents = this.students; 
        }).catch(error => {
          console.error('Error fetching students:', error);
        });
      },
      filterStudents(searchTerm) {
  if (searchTerm) {
    this.filteredStudents = this.students.filter(student =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  } else {
    this.filteredStudents = this.students;
  }
},
onSearchChange(searchTerm) {
  this.filterStudents(searchTerm);
},
      fetchParentAndFees(studentId) {
        api.get(`/students/${studentId}`).then(response => {
          this.parent = response.data.parent;
          this.fetchSubscriptionFees(studentId);
        }).catch(error => {
          console.error('Error fetching parent data:', error);
        });
      },
      fetchTreasuries() {
        api.get('/treasuries').then(response => {
          this.treasuries = response.data.filter(treasury => treasury.type === 'manual');
        }).catch(error => {
          console.error('Error fetching treasuries:', error);
          ElMessage.error('Error fetching treasuries.');
        });
      },
      updateFeeAmounts() {
        this.form.subscriptionFees = this.selectedFees.map(feeId => {
          const fee = this.subscriptionFees.find(f => f.id === feeId);
          return fee ? { subscription_fee_id: fee.id, amount: fee.amount, category: fee.category, sub_category: fee.sub_category } : {};
        });
      },
      submitForm() {
        if (!this.form.subscriptionFees.length) {
          ElMessage.error('Please select at least one subscription fee.');
          return;
        }
  
        if (this.form.value_received <= 0) {
          ElMessage.error('Please enter a valid amount received.');
          return;
        }
  
        api.post('/financial-document', this.form)
          .then(response => {
            ElMessage.success(response.data.message);
            this.receiptNumber = response.data.receipt_number;
          })
          .catch(error => {
            console.error('Error issuing financial document:', error);
            ElMessage.error('Failed to issue the financial document.');
          });
      }
    },
    mounted() {
      this.fetchStudents();
      this.fetchTreasuries();
    }
  };
  </script>
  
  <style>
  .logo {
    max-width: 150px;
    margin-top: 20px;
  }
  </style>
  