<template>
    <div>
      <el-form :model="form" label-width="150px" @submit.prevent="submitForm">
        <!-- Checkbox to check if the student exists -->
        <el-checkbox v-model="studentExists">Student Exists?</el-checkbox>
  
        <!-- If student exists, show a dropdown to select the student -->
        <el-select
          v-if="studentExists"
          v-model="form.student_id"
          filterable
          placeholder="Select Student"
          @change="onStudentSelected"
        >
          <el-option
            v-for="student in students"
            :key="student.id"
            :label="student.name"
            :value="student.id"
          />
        </el-select>
  
        <!-- Student information (visible when student does not exist) -->
        <el-form-item label="Student Name" v-if="!studentExists">
          <el-input v-model="form.name" placeholder="Student Name" />
        </el-form-item>
  
        <el-form-item label="Date of Birth" v-if="!studentExists">
          <el-date-picker v-model="form.age" type="date" placeholder="Pick a date" />
        </el-form-item>
  
        <el-form-item label="National Number" v-if="!studentExists">
          <el-input v-model="form.national_number" placeholder="National Number" />
        </el-form-item>
  
      <!-- Parent details when creating a new student -->
<div v-if="!studentExists">
    <el-divider>Parent Information</el-divider>
  
    <el-form-item label="Parent First Name">
      <el-input v-model="form.parent_details.first_name" placeholder="Parent First Name" />
    </el-form-item>
  
    <el-form-item label="Parent Last Name">
      <el-input v-model="form.parent_details.last_name" placeholder="Parent Last Name" />
    </el-form-item>
  
    <el-form-item label="Parent National Number">
      <el-input v-model="form.parent_details.national_number" placeholder="Parent National Number" />
    </el-form-item>
  
    <el-form-item label="Parent Phone Number">
      <el-input v-model="form.parent_details.phone_number_one" placeholder="Parent Phone Number" />
    </el-form-item>
  </div>
  
  
        <!-- Health File Details (Visible in both cases) -->
        <el-divider>Health Information</el-divider>
  
        <el-form-item label="Weight">
          <el-input v-model="form.weight" placeholder="Weight" />
        </el-form-item>
  
        <el-form-item label="Height">
          <el-input v-model="form.height" placeholder="Height" />
        </el-form-item>
  
        <el-form-item label="Blood Type">
          <el-input v-model="form.blood_type" placeholder="Blood Type" />
        </el-form-item>
  
        <el-form-item label="Medical History">
          <el-input v-model="form.medical_history" placeholder="Medical History" />
        </el-form-item>
  
        <el-form-item label="Hearing">
          <el-input v-model="form.hearing" placeholder="Hearing" />
        </el-form-item>
  
        <el-form-item label="Sight">
          <el-input v-model="form.sight" placeholder="Sight" />
        </el-form-item>
  
        <el-form-item label="Diabetes Mellitus">
          <el-checkbox v-model="form.diabetes_mellitus">Diabetes Mellitus</el-checkbox>
        </el-form-item>
  
        <el-form-item label="Food Allergies">
          <el-input v-model="form.food_allergies" placeholder="Food Allergies" />
        </el-form-item>
  
        <el-form-item label="Chronic Disease">
          <el-input v-model="form.chronic_disease" placeholder="Chronic Disease" />
        </el-form-item>
  
        <el-form-item label="Clinical Examination">
          <el-input v-model="form.clinical_examination" placeholder="Clinical Examination" />
        </el-form-item>
  
        <el-form-item label="Result of Clinical Examination">
          <el-input v-model="form.result_clinical_examination" placeholder="Result of Clinical Examination" />
        </el-form-item>
  
        <el-form-item label="Vaccinations">
          <el-input v-model="form.vaccinations" placeholder="Vaccinations" />
        </el-form-item>
  
        <!-- Submit Button -->
        <el-form-item>
          <el-button type="primary" @click="submitForm">Submit</el-button>
        </el-form-item>
      </el-form>
    </div>
  </template>
  
  <script>
  import { ElMessage } from 'element-plus';
  import axios from 'axios';
  
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  export default {
    data() {
      return {
        studentExists: false,
        form: {
          student_id: null,
          name: '',
          age: '',
          national_number: '',
          parent_details: {  // Ensure this object is included only if student_id is null
            first_name: '',
            last_name: '',
            national_number: '',
            phone_number_one: ''
          },
          weight: '',
          height: '',
          blood_type: '',
          medical_history: '',
          hearing: '',
          sight: '',
          diabetes_mellitus: false,
          food_allergies: '',
          chronic_disease: '',
          clinical_examination: '',
          result_clinical_examination: '',
          vaccinations: ''
        },
        students: []
      };
    },
    methods: {
      async fetchStudents() {
        try {
          const { data } = await api.get('/students-index');
          this.students = data;
        } catch (error) {
          ElMessage.error('Failed to load students');
        }
      },
      onStudentSelected(id) {
        const selectedStudent = this.students.find(student => student.id === id);
        this.form.student_id = selectedStudent.id; // Ensure student_id is set
        this.form.name = selectedStudent.name;
        this.form.age = selectedStudent.date_of_birth;
        this.form.national_number = selectedStudent.national_number;
        this.studentExists = true; // Indicate that a student is selected
      },
      async submitForm() {
        // Convert age to ISO format if it's a Date object
        if (this.form.age instanceof Date) {
          this.form.age = this.form.age.toISOString().split('T')[0];
        }
  
        // Prepare data to send to the API
        const payload = { ...this.form };
  
        // Remove parent_details if student_id is present
        if (this.form.student_id) {
          delete payload.parent_details;
        }
  
        try {
          await api.post('/health-files', payload);
          ElMessage.success('Health file submitted successfully');
          this.resetForm();
        } catch (error) {
          ElMessage.error('Failed to submit health file');
        }
      },
      resetForm() {
        this.form = {
          student_id: null,
          name: '',
          age: '',
          national_number: '',
          parent_details: {
            first_name: '',
            last_name: '',
            national_number: '',
            phone_number_one: ''
          },
          weight: '',
          height: '',
          blood_type: '',
          medical_history: '',
          hearing: '',
          sight: '',
          diabetes_mellitus: false,
          food_allergies: '',
          chronic_disease: '',
          clinical_examination: '',
          result_clinical_examination: '',
          vaccinations: ''
        };
        this.studentExists = false;
      }
    },
    mounted() {
      this.fetchStudents();
    }
  };
  </script>
  
  
  <style>
  /* Add any additional styling here */
  .el-form-item {
    margin-bottom: 20px;
  }
  .el-input, .el-select, .el-date-picker {
    width: 100%;
  }
  </style>
  